










import {Component} from '@/decorators';
import Vue from 'vue';

@Component({})
export default class PageView extends Vue {
    private content: string = '<h2>Page not found!</h2>';

    private loadedPageId: string = '';

    public mounted() {
        this.loadPage();
    }

    public updated() {
        // without this the page wouldn't be reloaded
        // when the route changed
        this.loadPage();
    }

    private loadPage() {
        let pageId = this.$route.params.pageId;
        if (this.loadedPageId === pageId) {
            return;
        }
        this.$http.get(`/contentPage/${pageId}`)
            .then(response => {
                this.loadedPageId = pageId;
                this.content = response.data.content;
                this.$nextTick(() => {
                    for (let item of (this.$refs.content as HTMLDivElement).getElementsByTagName('a')) {
                        if (item.href && item.href.endsWith('/#/google-analytics-opt-out')) {
                            item.addEventListener('click', event => {
                                event.preventDefault();
                                this.$ga.disable();
                                let in20Years = new Date(new Date().getTime() + 630720000000);
                                window.document.cookie = `ga-opt-out=true; expires=${in20Years}; path=/`;
                            });
                        } else {
                            item.target = '_blank';
                        }
                    }
                });
            });
    }
}
