var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-view container mt-4" },
    [
      _c("div", {
        ref: "content",
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.content) }
      }),
      _vm.$route.params.lastPage
        ? _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: _vm.$route.params.lastPage,
                  params: {
                    visitId: _vm.$route.params.visitId,
                    offerId: _vm.$route.params.offerId
                  }
                }
              }
            },
            [_vm._v("\n        zurück\n    ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }